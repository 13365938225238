import React from "react";
import { Container, Row, Nav, Col } from "react-bootstrap";

const Footer = () => {
  const products = [
    {
      name: "EmailGPT",
      link: "https://flerosoft.com/products/javascript/emailgpt-effortless-email-generation-powered-by-openai/",
    },
    {
      name: "Ad Block Detector Pro",
      link: "https://flerosoft.com/products/javascript/ad-block-detector-pro-light-weight-pure-javascript-plugin/",
    },
    {
      name: "Cookie Notice Pro",
      link: "https://flerosoft.com/products/javascript/cookie-notice-pro-advanced-jquery-plugin/",
    },
    {
      name: "Floating Help Center",
      link: "https://flerosoft.com/products/javascript/floating-help-center-advanced-jquery-plugin/",
    },
    {
      name: "iCaptcha",
      link: "https://flerosoft.com/products/php-scripts/icaptcha-frontend-backend-recaptcha-verification/",
    },
  ];
  return (
    <footer>
       <div className="py-5 mt-5" style={{ background: "#004a99", color: "#fff" }}>
        <Container>
          <Row>
            <Col md={3} className="mb-3">
              <img src="/white-logo.png" height="50" className="d-inline-block align-top" alt="Flerosoft" />
              <p className="mt-3">Empowering The Internet Generation</p>
            </Col>
            <Col md={3} className="mb-3">
              <h4 className="mb-3">Popular Products</h4>
              {products.map((product) => (
                <Nav.Link key={product.name} className="mb-2" href={product.link}>{product.name}</Nav.Link>
              ))}
            </Col>
            <Col md={3} className="mb-3">
              <h4 className="mb-3">Company</h4>
              <Nav.Link className="mb-2" href="https://flerosoft.com/about-us/">About</Nav.Link>
              <Nav.Link className="mb-2" href="https://flerosoft.com/privacy-policy/">Privacy Policy</Nav.Link>
              <Nav.Link className="mb-2" href="https://flerosoft.com/terms/">Terms</Nav.Link>
            </Col>
            <Col md={3} className="mb-3">
              <h4 className="mb-3">Connect</h4>
                <Nav.Link className="mb-2" href="https://codecanyon.net/user/flerosoft">Codecanyon</Nav.Link>
                <Nav.Link className="mb-2" href="https://flerosoft.com/contact-us/">Contact Us</Nav.Link>
                <Nav.Link className="mb-2" href="https://support.flerosoft.com">Support</Nav.Link>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="py-3 text-center " style={{ background: "#00428a", color: "#fff" }}>
          <p className="m-0">Copyright © {(new Date().getFullYear())} Flerosoft. All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
