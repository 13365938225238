import React, { useRef, useState } from "react"
import { Form, Button, Card } from "react-bootstrap"
import { UserAuth } from '../contexts/AuthContext';
import { toast } from "react-toastify";
import { useNavigate, useSearchParams  } from 'react-router-dom';

const ResetPassword = () => {
  const { resetPassword, firebaseErrorCodeMap } = UserAuth()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [searchParams, ] = useSearchParams();


  async function handleSubmit(e) {
    e.preventDefault()
    if(passwordRef.current.value === "" ) {
      toast.error("Password cannot be blank", {autoClose: 5000})
      return
    }
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      toast.error("Password cannot be blank", {autoClose: 5000})
      return 
    }
    setLoading(true)

    try {
      const oobCode =  searchParams.get('oobCode');
      const continueUrl =  searchParams.get('continueUrl');
      if(oobCode && continueUrl) {
        await resetPassword(oobCode, passwordRef.current.value)
        toast.success("Password Changed Successfully!", {autoClose: 5000})
        navigate("/")
      }
    } catch (e) {
      toast.error(firebaseErrorCodeMap[e.code] || "Error! Please try again later", {autoClose: 5000})
    } finally {
      setLoading(false)
    }
  }

  return (
    <div style={{ maxWidth: "400px" }} className="mx-auto">
      <Card  className="shadow rounded border-0 p-3">
        <Card.Body>
          <h2 className="text-center mb-4">Reset Password</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group id="password"  className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                placeholder="New Password"
                required
              />
            </Form.Group>
            <Form.Group id="password-confirm"  className="mb-3">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                ref={passwordConfirmRef}
                placeholder="Confirm New Password"
                required
              />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ResetPassword