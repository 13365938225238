import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react"
import { toast } from "react-toastify";
import { UserAuth } from "../../../contexts/AuthContext";

const TopNavbar = () => {
  const [error, setError] = useState("")
  const { user, logout } = UserAuth()
  const navigate = useNavigate();

  useEffect(() => {
    if(error) toast.error(error, {autoClose: 5000}) 
  }, [error]);

  const menuClickHandler = (event) => {
    event.preventDefault();
    const path = event.currentTarget.getAttribute('data-path');
    navigate(`/${path}`)
  }

  const logoutHandler = async (e) => {
    e.preventDefault();
    setError("")
    try {
      await logout()
      navigate("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  return (
    <Navbar bg="white" expand="lg" className="shadow-sm mb-5">
      <Container>
        <Navbar.Brand href="/">
          <img src="/logo.png" height="50" className="d-inline-block align-top" alt="Flerosoft" />
        </Navbar.Brand>
        {user && (<>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav className="ms-auto my-2 my-lg-0" style={{ maxHeight: "100px" }} navbarScroll >
            <NavDropdown title="My Account" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#" data-path="" onClick={menuClickHandler}>Dashboard</NavDropdown.Item>
              <NavDropdown.Item href="#" data-path="change-password"  onClick={menuClickHandler}>Change Password</NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={logoutHandler}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        </>)}
      </Container>
    </Navbar>
  );
};

export default TopNavbar;
