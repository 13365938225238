import { useEffect, useState } from "react";
import { Table } from "react-bootstrap"
import Spinner from 'react-bootstrap/Spinner';
import { UserAuth } from '../contexts/AuthContext';
import SingleTableItem from "./SingleTableItem";

export default function Dashboard() {
  const { user, fetchDownloadList } = UserAuth()
  const [downloads, setDownloads] = useState([]);
  const [showNotice, setShowNotice] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getDownloads = async () => {
      if (user) {
        setLoading(true)
        const downloads = await fetchDownloadList(user.uid);
        debugger
        setLoading(false)
        if (downloads?.length) {
          setDownloads(downloads);
        } else {
          setShowNotice(true)
        }
      }
    };
    getDownloads();
  }, [user, fetchDownloadList]);
  

  return (
    <>
    <h1 className="text-center mb-5">Your Purchases</h1>
    {loading && (<div style={{ display: 'flex', justifyContent: 'center' }}>
      <Spinner animation="border" variant="primary" />
    </div>)}
    {showNotice && (<p className="text-center">Dear User, no purchase has been made from your account. Please <a href="https://flerosoft.com/contact-us/">contact us</a> if you have any questions.</p>)}
    {downloads.length > 0 && (<Table striped bordered hover>
      <thead>
        <tr>
          <th>Product</th>
          <th>License</th>
          <th>Version</th>
          <th>Last Updated</th>
          <th>Download</th>
        </tr>
      </thead>
      <tbody>
          {downloads.map((product, index) => (
            <SingleTableItem product={product} key={index}/>
          ))}
      </tbody>
    </Table>)}
    </>
  )
}
