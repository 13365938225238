import axios from 'axios';
import { auth } from './firebase';

/**
 * Logger 
 * @param {String} type - type of log
 * @param {String} message - log message
 */
export const log = async (type, message) => {
  const email = auth?.currentUser?.email ?? "Not Available"
  const payload = {
    type:type,
    message:message,
    user:email,
    timestamp: `${new Date()}`
  }
  try {
    await axios.post(process.env.REACT_APP_BACKEND_LOG_URL, payload)
  } catch (error) {
    console.error(error);
  }
}