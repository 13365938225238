import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card } from "react-bootstrap"

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { UserAuth } from '../contexts/AuthContext';
import { log } from "../logger";
import GoogleAuthBtn from "./layout/GoogleAuthBtn/GoogleAuthBtn";

export default function Signup() {
  const location = useLocation()
  const emailRef = useRef()
  const passwordRef = useRef()
  // const fnameRef = useRef()
  // const lnameRef = useRef()
  const passwordConfirmRef = useRef()
  const { createUser, firebaseErrorCodeMap, createUserCollection } = UserAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    if(error) toast.error(error, {autoClose: 5000}) 
  }, [error]);

  async function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    try {
      setError("")
      setLoading(true)
      // const credentials = await createUser(emailRef.current.value, passwordRef.current.value)
      const credentials = await createUser(emailRef.current.value, passwordRef.current.value)
      log('auth', `New user has successfully signed up!`)
      await createUserCollection(credentials.user.uid)
      // await verifyEmail(credentials.user)
      // toast.success("Thank you! An email has been sent to your registered email address. Please check your inbox (and spam folder) and click the verification link to complete your account registration.", {autoClose:false})
      // await logout()
      navigate("/")
    } catch (e) {
      log('auth', `${emailRef.current.value} - ${e.message}`)
      setError(firebaseErrorCodeMap[e.code] || "Error")
    }

    setLoading(false)
  }

  return (
  <div style={{ maxWidth: "400px" }} className="mx-auto">
      <Card className="shadow rounded border-0 p-3">
        <Card.Body>
          <h2 className="text-center mb-4">Sign Up</h2>
          <GoogleAuthBtn/>
          <p className="text-center text-muted mb-3">------ or Sign up with Email ------</p>
          {/* {error && <Alert variant="danger">{error}</Alert>} */}
          <Form onSubmit={handleSubmit}>
            {/* <Form.Group id="fname">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="fname" ref={fnameRef} required />
            </Form.Group>
            <Form.Group id="lname">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="lname" ref={lnameRef} required />
            </Form.Group> */}
            <Form.Group id="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-confirm" className="mb-3">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Sign Up
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-4">
        Already have an account? <Link to={`/login${location.search}`}>Log In</Link>
      </div>
    </div>
  )
}
