import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../contexts/AuthContext';
import { toast } from "react-toastify";

export default function ChangePassword() {
  // const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { user, updateUserPassword, firebaseErrorCodeMap, logout } = UserAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    if(error) toast.error(error, {autoClose: 5000}) 
  }, [error]);

  async function handleSubmit(e) {
    e.preventDefault()
    if(passwordRef.current.value === "" ) {
      return setError("Password cannot be blank")
    }
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    // const promises = []
    setLoading(true)
    setError("")

    try {
      await updateUserPassword(passwordRef.current.value)
      toast.success("Password Changed Successfully!")
      navigate("/")
    } catch (e) {
      if(e.code === "auth/requires-recent-login") {
         toast.info("To change your password, you will need to log in again for security purposes. This will log you out of your current session, and you can log back in to update your password", {autoClose: false})
         await logout()
         navigate("/login")
      }
      setError(firebaseErrorCodeMap[e.code] || "Error")
    } finally {
      setLoading(false)
    }

  
    // if (passwordRef.current.value) {
    //   promises.push(updateUserEmail(passwordRef.current.value))
    // }

    // if (emailRef.current.value !== user.email) {
    //   promises.push(updateUserPassword(emailRef.current.value))
    // }

    // Promise.all(promises)
    //   .then(() => {
    //     navigate("/")
    //   })
    //   .catch(() => {
    //     setError("Failed to update account")
    //   })
    //   .finally(() => {
    //     setLoading(false)
    //   })
  }

  return (
    <div style={{ maxWidth: "400px" }} className="mx-auto">
      <Card  className="shadow rounded border-0 p-3">
        <Card.Body>
          <h2 className="text-center mb-4">Change Password</h2>
          {/* {error && <Alert variant="danger">{error}</Alert>} */}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email"  className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" defaultValue={user.email} readOnly disabled/>
            </Form.Group>
            <Form.Group id="password"  className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                placeholder="Leave blank to keep the same"
              />
            </Form.Group>
            <Form.Group id="password-confirm"  className="mb-3">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                ref={passwordConfirmRef}
                placeholder="Leave blank to keep the same"
              />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}
