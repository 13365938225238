import React from "react"
import { Container } from "react-bootstrap"
import Signup from "./components/Signup"
import Dashboard from "./components/Dashboard"
import Login from "./components/Login"
import ForgotPassword from "./components/ForgotPassword"
import ChangePassword from "./components/ChangePassword"

import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthContextProvider } from "./contexts/AuthContext";
import TopNavbar from "./components/layout/TopNavbar/TopNavbar"
import Footer from "./components/layout/Footer/Footer"
import ResetPassword from "./components/ResetPassword"

function App() {

  return (
    <AuthContextProvider>
      <header><TopNavbar/></header>
      <main className="d-flex flex-column justify-content-between" style={{minHeight:"100%"}}>
        <Container >
          <Routes>
            <Route exact path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup/>} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route exact path="/" element={ <ProtectedRoute> <Dashboard /> </ProtectedRoute>} />
            <Route path='/change-password'  element={ <ProtectedRoute> <ChangePassword /> </ProtectedRoute>} />
            <Route path='/reset-password'  element={ <ResetPassword /> } />
          </Routes>
        </Container>
        <Footer/>
      </main>
    </AuthContextProvider>
  )
}

export default App
