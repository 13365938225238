import { useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { BsBoxArrowInUpRight, BsDownload } from "react-icons/bs";
import { Button } from "react-bootstrap"
import { log } from "../logger";
import { UserAuth } from '../contexts/AuthContext';

const SingleTableItem = ({product}) => {
    const { user, downloadProduct } = UserAuth()
    const [isFileDownloading, setIsFileDownloading] = useState(false)

    const getFormattedDate = (dateObj) => {
        const day = dateObj.toDate().getDate();
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const month = monthNames[dateObj.toDate().getMonth()];
        const year = dateObj.toDate().getFullYear();
        return `${day} ${month} ${year}`;
    };
    
    const handleDownload = async (product) => {
        try {
          setIsFileDownloading(true)
          const { filename } = product;
          const url = await downloadProduct(filename);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          log('success', `File "${filename}" downloaded successfully.`)
        } catch (error) {
          log('error', `Failed to download file : ${error.message}`, user.email)
        } finally {
          setIsFileDownloading(false)
        }
    };

    return (
        <tr>
            <td>{product.name}<a href={product.product_url}><BsBoxArrowInUpRight className="ms-2"/></a></td>
            <td>Regular</td>
            <td>{product.version}</td>
            <td>{getFormattedDate(product.last_updated)}</td>
            <td><Button onClick={() => handleDownload(product)} className="w-100" type="button">{!isFileDownloading && <BsDownload/>} {isFileDownloading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} Download</Button></td>
        </tr>
    );
}

export default SingleTableItem;
