import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams  } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  sendEmailVerification,
  signInWithPopup,
  confirmPasswordReset
} from 'firebase/auth';
import { doc, setDoc, arrayUnion, updateDoc, getDoc } from "firebase/firestore";
import { auth, db, storage, provider } from '../firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import cryptoJs from 'crypto-js';
import { log } from '../logger';


const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const location = useLocation();
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  const [searchParams, ] = useSearchParams();

  const firebaseErrorCodeMap = {
    "auth/email-already-in-use": "The email address you provided is already in use",
    "auth/wrong-password": "The password you entered is invalid",
    "auth/weak-password": "The password you entered is too weak. Please choose a stronger password with at least 8 characters, including upper and lower case letters, numbers and symbols.",
    "auth/invalid-email": "The email address you provided is invalid.",
    "auth/operation-not-allowed": "Your account has been disabled. Please contact customer support for more information.",
    "auth/user-disabled" : "Your account has been disabled. Please contact customer support for more information.",
    "auth/user-not-found" : "Sorry, we couldn't find a user with that email address. Please check your email address and try again, or sign up if you haven't already.",
    "auth/invalid-action-code": "Sorry, the password reset link you are trying to use has expired. Please request a new link to reset your password."
  }

  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

   const login = (email, password) =>  {
    return signInWithEmailAndPassword(auth, email, password)
   }

   const googleAuth = () =>  {
    return signInWithPopup(auth, provider)
   }

  const logout = () => {
      return signOut(auth)
  }

  const forgotPassword = (email) => {
    return sendPasswordResetEmail(auth, email, {
      url:`${window.location.origin}${window.location.pathname}`
    })
  }

  const resetPassword = (oobCode, password) => {
    return confirmPasswordReset(auth, oobCode, password) 
  }

  const updateUserEmail = (email) => {
    return updateEmail(auth, email)
  }

  const updateUserPassword = (password) => {
    return updatePassword(user, password)
  }

  const verifyEmail = (user) => {
    return sendEmailVerification(user)
  }

  const createUserCollection = (uid) => {
    return setDoc(doc(db, "users", uid), {});
  }

  const downloadProduct = (fileName) => {
    const storageRef = ref(storage, `${fileName}.zip`)
    return getDownloadURL(storageRef);
  }

  const returnUrlRedirectionHandler = (user, returnUrl) => {
    const productId = searchParams.get('product_id');

    const payload = {
      auth_token:user.accessToken,
      email: user.email,
      product_id: productId,
      uid: user.uid
    }

    const encryptedPayload = cryptoJs.AES.encrypt(JSON.stringify(payload), process.env.REACT_APP_CRYPTOJS_SECRET).toString();
    window.location.href = `${returnUrl}?auth_code=${encodeURIComponent(encryptedPayload)}`
  }

  // const fetchUserData = async (uid) => {
  //   const docRef = doc(db, "users", uid);
  //   const docSnap = await getDoc(docRef);

  //   if (docSnap.exists()) {
  //     const userData = docSnap.data();
  //     return userData
  //   }
  // };

  const purchaseCompleteHandler = async (credentials, purchaseCode) => {
    const decryptedPurchaseCodeObj = JSON.parse(cryptoJs.AES.decrypt(decodeURIComponent(purchaseCode), process.env.REACT_APP_CRYPTOJS_SECRET).toString(cryptoJs.enc.Utf8))
    // const {checkout, product, user} = decryptedPurchaseCodeObj
    const {checkout, product } = decryptedPurchaseCodeObj
    const { name } = product
    const { passthrough, id } = checkout
    const { uid, fdi, version } = JSON.parse(passthrough) // get user uid and firebase product doc id
    const downloadInfo = {
      version: version,
      checkout_id: id,
      product_id:fdi
    }

    if (uid === credentials.uid) {
      const checkoutRef = doc(db, `users/${credentials.uid}/checkouts/${id}`);
      const userRef = doc(db, `users/${credentials.uid}`);
    
      try {
        await setDoc(checkoutRef, decryptedPurchaseCodeObj);
        
        // Check if the downloads field exists in the user document
        const userDoc = await getDoc(userRef);
        const userDocData = userDoc.data();
        const downloads = userDocData?.downloads || [];
        await setDoc(userRef, { downloads }, { merge: true });
        const keyExists = downloads.some(obj => obj.hasOwnProperty(fdi));
        if (!keyExists) {
          await updateDoc(userRef, { downloads: arrayUnion({ [fdi]: downloadInfo }) });
          log('purchase', `Purchase successful for ${name}. Checkout ID: ${id}`)
        }
      } catch (error) {
        log('purchase', `Error updating Firestore documents: ${error.message}`)
      } finally {
        navigate("/");
      }
    }
  }

  const searchParamsHandler = (user) => {
    const returnUrl = searchParams.get('return_url');
    const purchaseCode = searchParams.get('purchase_code')

    if(returnUrl) {
      returnUrlRedirectionHandler(user, returnUrl)
    } else if(purchaseCode) {
      purchaseCompleteHandler(user, purchaseCode)
    }
  }

  const fetchDownloadList = async (uid) => {
    try {
      const userRef = doc(db, `users/${uid}`);
      const userDoc = await getDoc(userRef);
      const { downloads } = userDoc?.data() ?? {};
      if (downloads) {
        const downloadList = await Promise.all(
          downloads.map(async (item) => {
            const [productId] = Object.keys(item);
            const productRef = doc(db, `products/${productId}`);
            const productDoc = await getDoc(productRef);
            return {...productDoc.data(), extra_info:item[productId]};
          })
        );
        return downloadList
      }
    } catch (error) {
      console.error("Error fetching downloads:", error);
      throw error;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      try {
        if (user && typeof user === "object") {
          setUser(user);
          setLoading(false);
          console.log(user) 
          searchParamsHandler(user); 
        } else {
          setUser(null);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error in onAuthStateChanged:", error);
        setUser(null);
        setLoading(false);
      }
    });
  
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  useEffect(() => {
    const { pathname } = location;
    const pageTitles = {
      '/': 'Dashboard | Members Flerosoft',
      '/login': 'Login | Members Flerosoft',
      '/signup': 'Signup | Members Flerosoft',
      '/forgot-password': 'Forgot Password | Members Flerosoft',
      '/change-password': 'Change Password | Members Flerosoft',
    };
    const DEFAULT_TITLE = 'Members Flerosoft';
  
    const pageTitle = pageTitles[pathname] || DEFAULT_TITLE;
    document.title = `${pageTitle}`;
  
    return () => {
      document.title = DEFAULT_TITLE;
    };
  }, [location]);

  useEffect(() => {
    if (!loading && user) {
      const excludedPaths = ["/login", "/signup", "/forgot-password"];
      if (excludedPaths.includes(location.pathname)) {
        navigate("/");
      }
    }
  }, [user, loading, location.pathname, navigate]);

  return (
    <UserContext.Provider value={{ createUser, user, logout, login, forgotPassword, updateUserEmail, updateUserPassword, verifyEmail,firebaseErrorCodeMap, createUserCollection, fetchDownloadList, downloadProduct, googleAuth, resetPassword }}>
      {!loading && children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};

