import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card } from "react-bootstrap"
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { UserAuth } from '../contexts/AuthContext';
import { log } from "../logger";
import GoogleAuthBtn from "./layout/GoogleAuthBtn/GoogleAuthBtn";

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login, firebaseErrorCodeMap } = UserAuth();
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(error) toast.error(error, {autoClose: 5000}) 
  }, [error]);

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      log('auth', `Login Successful`)
      navigate('/')
    } catch(e) {
      log('auth', `${emailRef.current.value} - ${e.message}`)
      setError(firebaseErrorCodeMap[e.code] || "Failed to log in")
    }
    setLoading(false)
  }

  return (
    <div style={{ maxWidth: "400px" }} className="mx-auto">
      <Card className="shadow rounded border-0 p-3">
        <Card.Body>
          <h2 className="text-center mb-3">Log In</h2>
          <GoogleAuthBtn/>
          <p className="text-center text-muted mb-3">------ or Sign in with Email ------</p>
          {/* {error && <Alert variant="danger">{error}</Alert>} */}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Log In
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-4">
        Need an account? <Link to={`/signup${location.search}`}>Sign Up</Link>
      </div>
    </div>
  )
}
