import React, { useRef, useState } from "react"
import { toast } from "react-toastify";
import { Form, Button, Card } from "react-bootstrap"
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../contexts/AuthContext';

export default function ForgotPassword() {
  const emailRef = useRef()
  const navigate = useNavigate()
  const { forgotPassword, firebaseErrorCodeMap } = UserAuth()
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setLoading(true)
      await forgotPassword(emailRef.current.value)
      toast.info("Please check your inbox (and spam folder) and follow the link provided to reset your password", {autoClose: 5000})
      navigate("/")
    } catch (e) {
      toast.error(firebaseErrorCodeMap[e.code] || "Failed to reset password", {autoClose: 5000})
    }
    setLoading(false)
  }

  return (
    <div style={{ maxWidth: "400px" }} className="mx-auto">
      <Card className="shadow rounded border-0 p-3">
        <Card.Body>
          <h2 className="text-center mb-4">Password Reset</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Reset Password
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">
            <Link to="/login">Login</Link>
          </div>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-4">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
    </div>
  )
}
